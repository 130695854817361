.pane.portfolio-mode {
	box-shadow: none;
	border-radius: 0;
	cursor: default;

	&:hover {
		box-shadow: none;
	}

	&.keep-hover-shadow {
		transition: 0.1s box-shadow;

		&:hover {
			box-shadow: 0 0 0.1em _palette(bg-alt), 0 0.25em 0.25em _palette(bg-alt), 0 1em 2em _palette(bg-alt);
		}
	}

	* {
		border-radius: 0;
	}

	.paneInner {
		padding: 0;
	}

	img.studio-profile-image {
		border-radius: 50%;
	}
}

.logo-small {
	width: 3em;
	height: 3em;
	border-radius: 50%;
	margin-right: 1em;
}

.portfolio-container {
	position: relative;
	display: flex;
	flex-direction: column;
}

.portfolio-header {
	position: relative;
	width: 100%;
	background-color: white;

	padding-bottom: _size(section-spacing-small) * 0.5;
	margin-bottom: _size(section-spacing-small) * 0.5;
	border-bottom: solid 1px _palette(border);

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		padding: 0;
		margin: 0;
	}

	.portfolio-site-title {
		display: flex;
		flex-direction: row;
		align-items: center;

		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 300;
		}
	}

	.portfolio-team-title {
		font-weight: 600;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		text-align: center;
		max-width: 400px;
	}

	.portfolio-header-layers {
		position: relative;
		margin-bottom: 0.5em;
	}

	.portfolio-header-layer {
		&:first-child {
			height: auto;
			position: relative;
			z-index: 500;
		}

		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.portfolio-header-layer .export-button {
		position: absolute;
		left: 80%;
		z-index: 501;
	}

	@include breakpoint(small) {
		.logo-small {
			width: 2em;
			height: 2em;
			margin-right: 0.8em;
		}

		.portfolio-header-layers {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.portfolio-header-layer {
			position: relative;
			width: auto;
			height: auto;
			margin-top: 0.8em;

			&:first-child {
				margin-top: 0;
			}
		}

		.portfolio-header-layer .export-button {
			display: none;
		}

		.portfolio-team-title {
			font-size: 1.1em;
			position: relative;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			max-width: 80vw;
		}
	}
}

.no-border {
	border: none;
	outline: none;
}

.portfolio-content {
	overflow-y: scroll;
	flex: 1;
	position: relative;

	&.portfolio-scroll {
		overflow-x: scroll;
	}

	&.portfolio-center {
		& > div {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
