.comment {
	border: solid 1px _palette(border);
	padding: 1em;
	overflow-wrap: anywhere;
	margin-bottom: 1em;
}

.comment-metadata {
	text-align: right;
	font-style: italic;
}