
#templateManager {
	border-bottom: solid 1px _palette(border);
	padding: 10px;
	text-align: center;
}

#requirementsContainer {
	position: relative;
}
