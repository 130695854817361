.Draftail-ToolbarButton {
	height: 2em;
	line-height: 1em;
}

.Draftail-Editor--readonly {
	color: black;
	border: none;
}

.Draftail-Editor--readonly .Draftail-Toolbar {
	display: none;
}

.Draftail-Editor--readonly .DraftEditor-editorContainer {
	opacity: 1.0;
}

.Draftail-Editor {
	border-radius: 0;
}

.DraftEditor-editorContainer {
	z-index: 0;
}

.Draftail-Editor .DraftEditor-root {
	font-size: 10pt;
}

.Draftail-Editor--readonly * > .public-DraftEditor-content {
	padding: 0;
}