#profile-image,
.profile-image,
.profile-image-overlay {
	width: 25em;
	border-radius: 50%;
	cursor: pointer;

	@include breakpoint(xsmall) {
		width: 100%;
	}
}

.profile-image-wrapper {
	align-items: center;
	position: relative;
}

.profile-image-overlay {
	background: _palette(bg-temp);
	opacity: 0.5;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		opacity: 1;
	}
}

.crop-image {
	margin-bottom: _size(section-spacing-small);
}
