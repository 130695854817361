.repo-select {
	width: 20em;
	margin-left: 0.5em;
	display: flex;
	flex-direction: column;
}

.commits-container {
	max-height: 40em;
	overflow: scroll;
}

.repos-count {
	color: _palette(fg-light);
	font-size: 85%;
    font-style: italic;
    text-align: center;
}

.login-error {
	color: red;
}