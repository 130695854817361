/* Upload */
#contributors {
	background-color: _palette(bg-alt);
	transform: scale(0.5, 0.5) !important;
	transform-origin: left top;
	display: flex;
	flex-direction: row;
}

#annotations-input,
#text-artifact {
	width: 100%;
}

.upload-section {
	padding-bottom: _size(element-margin);
}

.upload-sub-section {
	padding-top: _size(section-spacing-small);
}

.github-field {
	padding-top: _size(section-spacing-small) * 0.5;
}

.github-section > p {
	margin-bottom: 0;
}

#preview-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.preview-image {
	max-height: 10em;
	padding: 1em;
	vertical-align: middle;
}

.preview-image-block {
}

#upload-body {
	margin-bottom: _size(section-spacing) * 3;
}
