.assignmententry {
	padding: 1.5em;
	display: flex;
	flex-direction: row; 
	@include breakpoint(medium) {
		flex-direction: column; 
	}
}

.assignmententry .entry-name {
	width: 30%;
}

.assignmententry button {
	margin: 2em;
}

.reportsFromTemplate {
	flex-directon: column;
	width: 30%;
	margin-left: 2em;
}