.multi-selected {
	box-shadow: _palette(accent) 0px 0px 6px 11px;
    border-radius: 5px;
}

.pane-wrapper {
	position: absolute;
}

.layout-section {
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.layout-section .input-text {
	height: 100%
}

.layout-section .input-text button {
	height: 50%;
}


.z-index-controls {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: _palette(accent-transparent);
}

.z-index-controls button {
	background-color: white;
	margin: 2%;
	width: 40%;
	min-width: fit-content;
}

.z-index-controls button:hover {
	box-shadow: _palette(accent) 0px 0px 3px 3px;
    border-radius: 5px;
}