// Misc.
$misc: (z-index-base: 10000);

// Duration.
$duration: (menu: 0.5s, transition: 0.2s);
// Size.
$size: (
	big-element-height: 5em,
	element-height: 2.75em,
	element-margin: 2em,
	section-spacing: 3em,
	section-spacing-small: 1.5em
);
// Font.
$font: (
	family: ('Roboto', Helvetica, sans-serif),
	family-fixed: ('Courier New', monospace),
	family-heading: ('Raleway', Helvetica, sans-serif),
	weight: 400,
	weight-bold: 700,
	weight-heading: 400,
	weight-heading-bold: 800,
	weight-heading-extrabold: 900,
	kerning-heading: 0.15em
);
// Palette.
$palette: (
	bg: #ffffff,
	bg-temp: #d3d3d34a,
	bg-alt: #d3d3d3,
	explore-highlight: #fffce6,
	fg: #000000,
	fg-bold: #000000,
	fg-light: #000000,
	border-bg: rgba(160, 160, 160, 0.075),
	border: rgba(160, 160, 160, 0.3),
	border-alt: rgba(160, 160, 160, 0.65),
	accent: #1479fb,
	accent-transparent: rgba(20, 121, 251, .5),
	accent-mild: #1478fb37,
	accent-strong: #0d5fc5
);
