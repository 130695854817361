// Not found page (/*)

.not-found {
	align-self: center;
	text-align: center;
	vertical-align: middle;
	padding: 1.5em;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.not-found-buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.not-found-action {
	margin: 0.5em;
}
