.tag-instructions {
	font-size: 85%;
	font-style: italic;
}

.default-tags-container {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 2px 8px;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
}

.default-tag {
	font-style: italic;
	font-size: 85%;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	box-sizing: border-box;
	width: fit-content;

	display: flex;
	flex-direction: row;
	border-radius: 1em;
	border: 1px solid hsl(0, 0%, 90%);
	margin: 2px;
	min-width: 0;
	box-sizing: border-box;
	padding: .25em .75em;
}

.dropdown-select > * input[type="text"] {
	box-shadow: none;
}
