/* Tabbed Buttons */

.tab {
	height: 5em;
	line-height: 5em;
	padding: 0 1em;
	box-shadow: none;

	&.selected {
		background-color: _palette(accent);
		color: _palette(bg);
	}
}

.tabbed-buttons {
	display: flex;
	flex-direction: row;
	width: fit-content;
	height: fit-content;
	box-shadow: inset 0 0 0 1px _palette(bg-alt);
	align-self: center;
}
