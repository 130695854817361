#filter-pane {
	border-bottom: solid 1px _palette(border);
	margin-bottom: _size(section-spacing-small) * 0.5;
	padding-bottom: _size(section-spacing-small) * 0.25;
}

.section-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: _size(section-spacing-small) * 0.5;
	margin-bottom: _size(section-spacing-small) * 0.5;
	border-bottom: solid 1px _palette(border);

	@include breakpoint(small) {
		flex-direction: column-reverse;
	}
}

#zoom {
	@include breakpoint(small) {
		width: 100%;
		margin-bottom: _size(section-spacing-small) * 0.5;
	}
}

.section-label-row {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.section-label-row > .icon {
	padding-right: 0.5em;
}

.section-label-row > p {
	margin-bottom: 0;
}

.filter-section {
	padding-bottom: 0.5em;
}

.filter-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 0.25em;
}

.filter-buttons {
	display: flex;
	flex-direction: row;
}

.filter-controls {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}

.filter-title > h4,
.filter-section > p,
.filter-controls > p,
.section-label-row > p {
	margin: 0;
}

.filter-controls > .checkbox {
	padding-right: 1em;
}

.datefilter-select {
	margin-right: 0.5em;
	width: 7em;
}

.team-select {
	width: 20em;
	margin-left: 0.5em;
	display: flex;
	flex-direction: column;

	@include breakpoint(small) {
		margin-bottom: _size(section-spacing-small) * 0.5;
	}
}

.gallery-grid {
	overflow: visible;
	padding-bottom: _size(section-spacing-small);
}

.gallery-sort {
	width: 8.5em;

	&.in-filters {
		margin-left: 0.5em;
	}
}

// styling for react-datepicker

.react-datepicker-wrapper {
	margin: 0 0.5em 0 0.5em;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container > input {
	width: 6.75em;
}

.react-datepicker-popper {
	z-index: 3;
}

.react-datepicker {
	font-family: inherit;
}

.instructor-stuff {
	background-color: #ffece6;
	padding: 2em;
}

.team-info {
	display: flex;
	flex-direction: row;
	@include breakpoint(small) {
		flex-direction: column-reverse;
	}
}

.studio-profile-image {
	width: 25em;
	border-radius: 50%;

	@include breakpoint(xsmall) {
		width: 100%;
	}
}

.team-images {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-left: 1em;
	height: fit-content;
	@include breakpoint(small) {
		margin-bottom: _size(section-spacing-small) * 0.5;
	}
}

.team-images img {
	width: 3.25em;
	height: 3.25em;
	margin-left: 0.2em;
}
