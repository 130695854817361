/* Wrapper */

#wrapper {
	height: 100%;
	overflow: hidden; /*makes the body non-scrollable (we will add scrolling to the main content containers)*/
	display: flex;
	flex-direction: row;
}

#main-content {
	height: 100vh;
	overflow-y: scroll;
	margin: 0 auto;
	max-width: 100%;
	padding: _size(section-spacing-small);
	flex-basis: 100%;
	body.single & {
		display: block;
	}
}

#loading-wrapper {
	height: 100vh;
}

#loading-inner-wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
