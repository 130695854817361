.panes {
	display: flex;
	flex-wrap: wrap;
}

.pane {
	width: 100%;
	height: 100%;
	background-color: _palette(bg);
	font-size: 10pt;
	box-shadow: 0 0 0.1em _palette(bg-temp), 0 0.2em 0.25em _palette(bg-alt), 0 1.5em 2em _palette(bg-temp);
	cursor: pointer;
	border-radius: 0.25em;

	&:hover {
		box-shadow: 0 0 0.1em _palette(bg-alt), 0 0.25em 0.25em _palette(bg-alt), 0 1em 2em _palette(bg-alt);
	}
	position: relative;
	overflow: hidden;
}

.launch-detail {
	width: 30%;
}

.launch-detail:hover {
	color: _palette(accent);
}

.paneInner {
	padding: 5%;
	//height: auto;
	min-height: min-content;
	min-width: min-content;
}

.paneInnerHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.paneInnerSmall {
	font-size: 4pt;
}

.artifact-pane {
	height: 100%;
	overflow: hidden;
}

.artifact-pane > a {
	min-height: min-content;
	min-width: min-content;
	border-bottom: none;
	text-decoration: none;
	&:hover {
		color: _palette(fg);
	}
}

.paneInner .pane-text {
	max-height: 25em;
	white-space: pre-line;
}

.paneInner.paneInnerSmall .pane-text {
	max-height: 15em;
}

.pane-text {
	white-space: pre-line;
}

.player-wrapper {
	position: relative;
	width: 100%;
}

.paneInner .paneyoutube {
	border-radius: 0.25em !important;
	max-height: 25em;
	width: 100%;
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.paneInfoTeeny {
	font-size: 4pt;
}

.paneInner > img {
	border-radius: 0.25em;
}

.paneimg {
	width: 100%;
	margin-bottom: _size(section-spacing-small) * 0.5;
	display: flex;
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.panefigma {
	width: 100%;
	padding-bottom: _size(section-spacing-small) * 0.5;
}

.panevideo {
	padding-bottom: _size(section-spacing-small) * 0.5;
}

.paneyoutube {
	padding-bottom: _size(section-spacing-small) * 0.5;
}

.pane-link {
	padding-bottom: _size(section-spacing-small) * 0.5;
}

.feedbackCount {
	font-size: 13px;
	position: absolute;
	text-align: center;
	width: 26px;
	> h3 {
		color: #ffffff;
	}
}

.pane-title {
	max-height: 5em;
	overflow: hidden;
}

.pane-selected {
	// (JV) Removing this formatting for portfolio mode.
	// border: 1px solid _palette(accent-mild);
}

.paneInfo {
	width: auto;
	height: auto;
}

.github-artifact-text {
	margin-bottom: 0;
}

.team-faux-artifact {
	& > div {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 1em;

		&:first-child {
			margin-top: 0;
		}
	}

	& > div * {
		display: inline-block;
	}

	img {
		width: 4em;
		height: 4em;
		margin-right: 1em;
		pointer-events: none;
	}

	p {
		margin: 0;
		padding: 0;
	}
}

// Pane sidebar buttons

.pane-sidebar {
	$pad: 1em;
	$padColor: rgba(255, 255, 255, 0.85);

	position: absolute;
	padding: $pad;
	background: $padColor;

	top: 1em;
	right: 1em;
	border-radius: 0.25em;
	box-shadow: 0 0 0.1em _palette(bg-temp), 0 0.2em 0.25em _palette(bg-alt), 0 1.5em 2em _palette(bg-temp);

	opacity: 0;
	transition: opacity 0.2s;

	z-index: 0;

	&.show {
		opacity: 1;
		z-index: 100;
	}

	& > .pane-sidebar-item {
		margin-top: $pad;
		display: block;

		&:first-child {
			margin-top: 0;
		}
	}
}
