/* Tag */
.tag {
	border-radius: 0;
    color: hsl(0,0%,20%);
    font-size: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    width: fit-content;
}

.tag.clickable {
	&:hover {
		box-shadow: inset 0 0 0 1px _palette(bg-temp);
		background-color: _palette(bg-temp);
	}

	&.selected {
		background-color: _palette(bg-alt);

		&:hover {
			box-shadow: inset 0 0 0 1px _palette(bg-temp);
			background-color: _palette(bg-temp);
		}
	}
}

.tag-content {
	display: flex;
	flex-direction: row;
	background-color: hsl(0,0%,90%);
    border-radius: 1em;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    padding: .25em .75em;
}

.tag-content > .icon {
	padding-left: 0.5em;
}


