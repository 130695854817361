#sidebar {
	flex-shrink: 0; /*makes sure that content is not cut off in a smaller browser window*/
	flex-flow: column;
	overflow: hidden;
	border-right: solid 1px _palette(border);
	display: flex;
	justify-content: space-between;
	max-width: 15em;

	a {
		color: inherit;
		text-decoration: none;
		border-bottom: none;
	}

	p,
	h3,
	h6 {
		margin: 0;
		line-height: 0;
		align-self: center;
	}

	.index-link {
		z-index: 3;
		display: flex;
		align-items: center;
	}

	.sidebar-item {
		padding: _size(section-spacing-small);
		display: flex;
		align-items: center;
	}

	.icon {
		line-height: 0;
	}

	.label {
		padding-left: _size(section-spacing-small);
	}
}

.sidebar-nav-item {
	display: flex;
	flex-direction: row;
	padding: _size(section-spacing-small)/2 _size(section-spacing-small) _size(section-spacing-small)/2
		_size(section-spacing-small);

	&:hover {
		background: _palette(bg-temp);
	}
}

#selected {
	background: _palette(bg-alt);
}

#signout-button {
	width: 100%;
}

a#feedback-link {
	color: _palette(accent-strong);
	cursor: pointer;
	font-family: _font(family-heading);
	font-size: 0.6em;
	font-weight: _font(weight-heading-bold);
	height: _size(element-height) * 1.75;
	letter-spacing: _font(kerning-heading);
	line-height: _size(element-height) * 1.75;
	text-transform: uppercase;
	white-space: nowrap;
	text-decoration: underline;
	width: 100%;
}
