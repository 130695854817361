/* Input */

// Text Input
input[type="text"],
textarea {
	border-radius: 0;
	border: 0;
	box-shadow: inset 0 0 0 1px _palette(border);
	width: fit-content;
	padding: 0.25em 0.5em 0.25em 0.5em;
	line-height: 1.8em;

	&:focus {
		outline-width: 0;
	}
}

textarea {
	resize: none;
	padding-bottom: 1.5em;
}

.input-text {
	position: relative;
}

.input-text > input[type="text"] {
	width: 100%;
}

.layoutName {
	height: 200px;
}

// Tag Selector (nexted input text element)
.tagSelectorDropdown * input[type="text"] {
	box-shadow: none;
}


.input-text .warning {
	color: red;
}
