#portfolio-button {
	background-color: #fffce6;
	&:hover {
		background-color: _palette(accent-mild);

		&:active {
			background-color: _palette(accent-strong);
			color: _palette(bg) !important;
		}
	}
}

.portfolioify-modal-inner {
	margin-top: 100px;
	margin-bottom: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;

	* {
		margin-top: 20px;

		&:first-child {
			margin-top: 0;
		}
	}

	.portfolioify-loading {
		margin-top: 60px;
		height: 39px;
		width: 40px;
	}
}
