/* Artifact Detail */
#artifact-view {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: calc(100% - 3.5em);
	@include breakpoint(small) {
		height: auto;
		flex-direction: column;
		max-height: none;
	}
}

#artifact-column {
	display: flex;
	flex-direction: column;
	overflow: scroll;
	width: 50%;
	max-width: 50%;
	padding: 0 _size(section-spacing-small) 0 _size(section-spacing-small);

	@include breakpoint(small) {
		overflow: visible;
		height: auto;
		width: auto;
		max-width: 100%;
		padding: 0;
	}
}

#metadata-column {
	display: flex;
	flex-direction: column;
	overflow: scroll;
	width: 50%;
	padding: 0 _size(section-spacing-small) 0 _size(section-spacing-small);
	border-left: solid 1px _palette(border);

	@include breakpoint(small) {
		overflow: visible;
		width: 100%;
		padding: 0;
		border-left: none;
	}
}

.metadata-section {
	border-top: solid 1px _palette(border);
	margin-top: _size(section-spacing-small);
	padding-top: _size(section-spacing-small);
}

.artifact-details {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.artifact-metadata-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.artifact-section {
	margin-bottom: _size(section-spacing-small);
}

.report-artifact-container {
	cursor: pointer;
	margin-bottom: _size(section-spacing-small);
}

#annotations {
	width: 100%;
}

.loading {
	height: 94vh;
	display: flex;
}

.tag-container {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 2px 8px;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
}
