///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
	@include vendor('appearance', 'none');
	@include vendor('transition', (
			'background-color #{_duration(transition)} ease',
			'box-shadow #{_duration(transition)} ease',
			'color #{_duration(transition)} ease'
		));
	background-color: transparent;
	border: 0;
	box-shadow: inset 0 0 0 1px _palette(border);
	color: _palette(accent-strong);
	cursor: pointer;
	display: inline-block;
	font-family: _font(family-heading);
	font-size: 0.6em;
	font-weight: _font(weight-heading-bold);
	height: _size(element-height) * 1.75;
	letter-spacing: _font(kerning-heading);
	line-height: _size(element-height) * 1.75;
	padding: 0 2.5em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;

	&:hover {
		background-color: _palette(accent-mild);

		&:active {
			background-color: _palette(accent-strong);
			color: _palette(bg) !important;
		}
	}

	&:before,
	&:after {
		color: _palette(fg-light);
		position: relative;
	}

	&:before {
		left: -1em;
		padding: 0 0 0 0.75em;
	}

	&:after {
		left: 1em;
		padding: 0 0.75em 0 0;
	}

	&.fit {
		display: block;
		margin: 0 0 (_size(element-margin) * 0.5) 0;
		width: 100%;
	}

	&.big {
		font-size: 0.7em;
		padding: 0 3em;
	}

	&.small {
		font-size: 0.5em;
	}

	&.disabled,
	&:disabled {
		@include vendor('pointer-events', 'none');
		color: _palette(border) !important;

		&:before {
			color: _palette(border) !important;
		}
	}

	&:focus {
		outline-width: 0;
	}

	&.clickable-icon {
		box-shadow: none;
		padding: 0;
		height: fit-content;
		line-height: 0;
		color: _palette(fg-bold) !important;

		&:hover {
			box-shadow: none;
			background-color: transparent;

			&:active {
				background-color: transparent;
				color: _palette(accent-strong) !important;
			}
		}
	}

	&.input-save {
		height: 2.5em;
		line-height: 2.5em;
		padding: 0 1em;
		background-color: _palette(accent);
		box-shadow: none;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		color: _palette(bg);

		&:hover {
			background-color: _palette(accent-strong);
		}

		&:disabled {
			@include vendor('pointer-events', 'none');
			color: _palette(border) !important;
			background-color: _palette(bg);
			box-shadow: inset 0 0 0 1px _palette(border);
		}
	}

	&.primary {
		background-color: _palette(accent);
		box-shadow: inset 0 0 0 1px _palette(accent);
		color: _palette(bg) !important;

		&:hover {
			background-color: _palette(accent-strong);
			box-shadow: inset 0 0 0 1px _palette(accent-strong);
			color: _palette(bg) !important;

			/*&:active {
				color: _palette(bg) !important;
			}*/
		}

		&.disabled,
		&:disabled {
			background-color: _palette(bg-temp);
			box-shadow: inset 0 0 0 1px _palette(border);
			color: _palette(border) !important;
		}
	}

	&.icon-button {
		height: _size(element-height);
		width: _size(element-height);
		background-color: _palette(accent);
		color: _palette(bg);
		padding: 0;
		align-self: flex-end;

		&:hover {
			background-color: _palette(accent-strong);
		}
	}

	&.big-icon-button {
		height: _size(big-element-height);
		width: _size(big-element-height);
		background-color: _palette(accent);
		color: _palette(bg);
		padding: 0;
	}

	&.smolButton {
		height: _size(element-height);
		text-align: center;
		line-height: _size(element-height) * 1.25;
	}
}
