/* Checkbox */
.checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.checkbox > p {
	margin: 0 0.5em 0 0.5em;
}
