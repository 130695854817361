.closemodal {
	text-align: right;
}

.Modal {
	position: absolute;
	top: 10%;
	left: 5%;
	right: auto;
	bottom: auto;
	width: 90%;
	background-color: #ffffff;
	padding: 1em;
	overflow: scroll;
	max-height: 80vh;
	&:focus {
		outline: none;
	}
}

.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}
