.requirement {
	padding: _size(section-spacing-small);
	margin: _size(section-spacing-small);
	margin-bottom: _size(section-spacing);
}

.requirementArtifactType {
	display: inline-block;
	font-style: italic;
	width: 50%;
}

.requirementTag {
	display: inline-block;
	width: 50%;
}

.deletebutton {
	text-align: right;
}
