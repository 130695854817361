.signin-content {
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: _size(section-spacing-small);
	@include breakpoint(xsmall) {
		flex-direction: column;
	}
}

.logo-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 0;

	> * h2,
	> * h6 {
		margin: 0;
	}

	@include breakpoint(xsmall) {
		padding-bottom: _size(section-spacing-small) * 0.5;
	}
}

.title-subtitle {
	align-items: center;
	line-height: 0;
	flex-direction: column;
	display: flex;
	padding-left: _size(section-spacing-small) * 0.5;
}

.showcase {
	padding: _size(section-spacing-small);
	display: grid;
	overflow: hidden;
	grid-template-columns: repeat(3, 1fr); // 3 columns
	grid-column-gap: 0.5em;
	grid-row-gap: 0.5em;
	width: 100%;

	@include breakpoint(medium) {
		grid-template-columns: repeat(2, 1fr); // 2 columns
	}

	@include breakpoint(small) {
		grid-template-columns: repeat(1, 1fr); // 2 columns
	}
}

.showcase-info {
	display: flex;
	flex-direction: column;
}

.showcase-info-text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.card {
	width: 100%;
	background-color: _palette(bg);
	font-size: 10pt;
	box-shadow: 0 0 0.1em _palette(bg-temp), 0 0.2em 0.25em _palette(bg-alt), 0 1.5em 2em _palette(bg-temp);
	cursor: pointer;
	border-radius: 0.25em;
	margin-bottom: 1em;
	height: fit-content;

	&:hover {
		box-shadow: 0 0 0.1em _palette(bg-alt), 0 0.25em 0.25em _palette(bg-alt), 0 1em 2em _palette(bg-alt);
	}
	position: relative;
}

.card-content {
	padding: 1.5em;
	display: flex;
	flex-direction: column;
}

.centered-signin-content {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 10em;

	> sub {
		color: red;
	}
}

#signin-button {
	margin-bottom: _size(section-spacing-small);
}
