.admin-section {
	padding-top: 3em;
}

.admin-section textarea {
	width: 100%;
}

.admin-section button {
	margin-left: 1em;
}
